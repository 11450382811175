
<template>
  <div class="company_manage" v-loading="loading">
    <div class="submitted_auth" v-if="companyInfo">
      <template v-if="!updateOrView">
        <div class="companyName">{{companyInfo.companyName}}</div>
        <div :class="statusClass">{{ statusText }}</div>
        <div class="reason" v-if="companyInfo.status==2">失败原因：<span>{{ companyInfo.rejectContent }}</span></div>
        <el-button type="primary" class="common-btn" @click="updateOrViewClick(buttonText)">{{buttonText}}</el-button>
      </template>
      <template v-else>
        <Detail :updateOrView="updateOrView" :companyId="companyId" @cancelClick="cancelClick"></Detail>
      </template>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>

import Detail from './Detail.vue'
import api from '@/api/api'
export default {
  data () {
    return {
      authStatus: true,
      companyInfo: null,
      updateOrView: '',//更新 update   查看view
      companyId: "",
      loading: false
    }
  },
  components: {
    Detail,
  },
  computed: {
    isAuthRoute () {
      return this.$route.name === '企业认证';
    },
    statusText () {
      switch (this.companyInfo.status) {
        case 0: return '审核中';
        case 1: return '已认证';
        case 2: return '认证失败';
        default: return '未知状态';
      }
    },
    statusClass () {
      switch (this.companyInfo.status) {
        case 0: return 'status-auditing';
        case 1: return 'status-verified';
        case 2: return 'status-failed';
        default: return '';
      }
    },
    buttonText () {
      return this.companyInfo.status === 0 ? '查看' : '更新';
    },
  },
  created () {
    const companyData = window.localStorage.getItem('company');
    if (companyData) {
      let info = JSON.parse(companyData);
      this.companyId = info && info.id;
      this.getCompanyDetails(this.companyId)
    }

  },
  methods: {
    updateOrViewClick (text) {
      this.updateOrView = text === '更新' ? 'update' : 'view';
    },
    cancelClick () {
      this.updateOrView = false;
    },
    async getCompanyDetails (id) {
      this.loading = true;
      const result = await api.queryCompanyDetail(id)
      this.companyInfo = result.data;
      localStorage.setItem('company', JSON.stringify(result.data))
      this.loading = false;
    },

  },
}
</script>

<style lang="scss" scoped>
.submitted_auth {
  display: flex;
  align-items: center;

  .companyName {
    margin-right: 30px;
  }
  .status-auditing {
    color: orange;
    font-weight: bold;
  }
  .status-verified {
    color: green;
    font-weight: bold;
  }
  .status-failed {
    color: red;
    font-weight: bold;
  }
  .el-button {
    margin-left: 80px;
  }
}
.reason {
  margin-left: 50px;
}
</style>
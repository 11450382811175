<template>
  <div class="company_wrap">
    <div class="company_detail" v-loading="!form.companyName">
      <el-button type="primary" class="common-btn" @click="cancel">返回</el-button>
      <el-form ref="companyForm" label-position="right" :rules='rules' :model="form" label-width="140px">
        <div class='group_title'>企业信息</div>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="企业名称：" prop="companyName">
              <el-input :readonly="isViewing" v-model='form.companyName' ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="营业执照扫描件：" prop="imageUrl">
              <el-upload v-if="!isViewing" class="avatar-uploader" action="" :show-file-list="false"
                :http-request='uploadFile' :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                accept=".jpg,.png,.jpeg">
                <!-- <el-image v-if="licenceUrl" :src="licenceUrl" class="avatar" :preview-src-list="[licenceUrl]"></el-image> -->
                <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div class="el-upload__tip" slot="tip">请提供证件的原件照片或彩色扫描件（正副本均可），文字/盖章清晰可辨认，格式要求jpg、jpeg、png，不超过4MB
                </div>
              </el-upload>
              <el-image v-if="isViewing" :src="licenceUrl" alt="" :preview-src-list="[licenceUrl]"></el-image>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="所属行业：" prop="industry">
              <el-select :disabled="isViewing" v-model="form.industry" >
                <el-option v-for="(item,index) in industryList" :key="index" :label="item.name"
                  :value="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="官网地址：" prop="companyUrl">
              <el-input :readonly="isViewing" v-model='form.companyUrl'></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="公司简介：" prop="companyDetail">
              <el-input :readonly="isViewing" type="textarea" v-model='form.companyDetail' maxlength="500"
                show-word-limit :autosize="{ minRows: 3, maxRows: 8}" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="公司地址：" prop="address">
              <el-input :readonly="isViewing" v-model='form.address' ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="资质荣誉：">
              <el-upload v-if="!isViewing" action="" class="upload-demo" drag :on-change='changeFileList'
                :file-list='fileList' :on-remove="removeAptitude" :http-request='uploadFiles' multiple
                accept=".jpg,.png,.jpeg">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传.jpg .png .jpeg文件</div>
              </el-upload>
              <div class="aptitudeList" v-if="isViewing">
                <el-image v-for="(item,index) in aptitudeUrl" :src="item" alt="" :key="index"
                  :preview-src-list="aptitudeUrl"></el-image>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <div class='group_title'>联系人信息</div>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="联系人姓名：" prop="contactName">
              <el-input :readonly="isViewing" v-model='form.contactName' ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="联系人职务：" prop="contactPosition">
              <el-input :readonly="isViewing" v-model='form.contactPosition' ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="联系人电话：" prop="contactPhone">
              <el-input :readonly="isViewing" v-model='form.contactPhone'
               ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='span'>
            <el-form-item label="联系人邮箱：" prop="email">
              <el-input :readonly="isViewing" v-model='form.email' ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="opera_btn" v-if="updateOrView=='update'">
        <el-button @click='cancel' class="common-btn" type="primary"> 取 消</el-button>
        <el-button @click='submitForm' :loading="submitLoading" class="common-btn" type="primary"> 确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api'
export default {
  props: {
    updateOrView: {//更新 update   查看view
      type: String,
      default: ''
    },
    companyId: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      form: {
        companyName: "",
        industry: "",
        companyUrl: "",
        companyDetail: "",
        address: "",
        contactName: "",
        contactPosition: "",
        contactPhone: "",
        email: "",
        imageUrl: '',
      },
      span: 22,
      file: '',
      fileList: [],

      rules: {
        companyName: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        companyDetail: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        contactName: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        contactPosition: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        contactPhone: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        industry: [
          { required: true, message: '请填写必填项', trigger: 'change' },
        ],
        imageUrl: [
          { required: true, message: '请填写必填项', trigger: 'change' },
        ],
      },
      industryList: [],
      licenceUrl: '',
      aptitudeUrl: [],
      submitLoading: false
    }
  },
  computed: {
    isViewing () {
      return this.updateOrView == 'view'
    }
  },
  created () {
    if (this.companyId) {
      this.getIndustryList();
      this.getCompanyDetails(this.companyId)
    }

  },
  methods: {
    getIndustryList () {
      api.queryDictDetail('industry_type').then(res => {
        if (res.code == 200) {
          this.industryList = res.data;
        }
      })
    },
    async imageUrlToFile (imageUrl) {
      const fileName = imageUrl.split('/').pop();
      try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
          throw new Error('图片加载失败');
        }
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        return { raw: file, name: fileName };
      } catch (error) {
        console.error('error', error);
      }
    },
    async imagesUrlToFiles (imagesUrl) {
      try {

        const promises = imagesUrl.map(url => this.imageUrlToFile(url));
        const files = await Promise.all(promises);
        return files;
      } catch (error) {
        console.error('图片转换失败:', error);
      }
    },
    async getCompanyDetails (id) {
      const result = await api.queryCompanyDetail(id)
      this.licenceUrl = result.data.licenceUrl
      this.aptitudeUrl = result.data.aptitudeUrl;
      this.form.imageUrl = this.licenceUrl;
      if (this.licenceUrl.length > 0) {
        this.file = await this.imageUrlToFile(this.licenceUrl);

      }
      if (this.aptitudeUrl.length > 0) {
        this.fileList = await this.imagesUrlToFiles(this.aptitudeUrl);
        this.fileList = this.fileList.filter(item => item != null);


      }
      Object.keys(this.form).forEach(key => {
        if (result.data.hasOwnProperty(key)) {
          this.form[key] = result.data[key]!='null'?result.data[key]:'';
        }
        this.form.id = this.companyId;
      });
    },
    uploadFile (uploadData) {
      uploadData.onSuccess(uploadData.file);
    },
    handleAvatarSuccess (res, file) {
      this.file = file;
      this.form.imageUrl = URL.createObjectURL(file.raw);
    },
    uploadFiles () {
    },
    removeAptitude (file, fileList) {
      console.log(fileList, 'fileListfileList')
      this.fileList = fileList;
    },
    beforeAvatarUpload (file) {
      const allowedTypes = ['image/jpeg', 'image/png'];
      const isAllowedType = allowedTypes.includes(file.type);
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isAllowedType) {
        this.$message.error('上传头像图片只能是 JPG、JPEG 或 PNG 格式!');
        return false;
      }
      if (!isLt4M) {
        this.$message.error('上传头像图片大小不能超过 4MB!');
        return false;
      }
      return true;
    },
    changeFileList (file, fileList) {
      this.fileList = fileList;

    },
    reset () {
      this.file = ''
      this.fileList = []
      this.form = {}
      this.companyVisible = false
      this.$refs.companyForm.resetFields();
    },
    cancel () {
      this.$emit('cancelClick')
    },
    submitForm () {
      this.$refs.companyForm.validate((valid) => {
        if (valid) {
          this.loading = true
          let formData = new FormData()
          for (var key in this.form) {
            if (Object.prototype.hasOwnProperty.call(this.form, key)) {
              if (key !== 'imageUrl') {
                formData.append(key, this.form[key]);
              }
            }
          }
          if (this.file) {

            formData.append('file', this.file.raw);
          }
          if (this.fileList && this.fileList.length > 0) {
            this.fileList.forEach((item) => {
              formData.append(`files`, item.raw);
            });
          } else {
            formData.append(`files`, null);
          }
          // if(this.fileList && this.fileList.length > 0){
          //     let files = this.fileList.map((item,index) => {
          //         return item.raw
          //     })
          //     formData.append(`files`, files)
          // }

          this.submitLoading = true;
          api.companyModify(formData).then(res => {
            this.loading = false;
            this.submitLoading = false;
            if (res.code == 200) {
              window.localStorage.setItem('company', JSON.stringify(res.data))
              window.location.reload();
            }
          })
            .catch(err => {
              this.loading = false
            })
        } else {
          return false;
        }
      });
    },
  },
}
</script>

<style lang="scss">
.company_wrap {
  width: 100%;
  background: #fff;
}
.company_detail {
  background: #fff;

  padding: 20px;
  width: 800px;
  .el-image,
  img {
    width: 150px;
    height: auto;
  }

  .opera_btn {
    text-align: center;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .group_title {
    font-size: 20px;
    margin: 18px 0px;
  }
}
</style>